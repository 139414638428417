.nuteplan {
}

.background-container {
  position: relative;
  overflow: hidden;
}

.img {
  width: 100%;
  height: 100%;
  // height: auto;
  display: block;
  // position: relative;

  @media (max-width: 1268px) {
    height: 500px;
  }
}
.content-container {
  position: absolute;
  top: 0;
  left: 2%;
  width: 50%;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  @media (max-width: 768px) {
    width: 100%;
    left: 0;
    margin-bottom: 100px;
  }
}

.text-container {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 40px;
  border-radius: 8px;
  text-align: left;
  max-width: 700px;
  z-index: 99999;
}

.heading {
  font-size: 33px;
  margin-bottom: 10px;
  color: #86c351;
  font-weight: 600;
}

h4 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #86c351;
  font-size: 20px;
  font-weight: 600;
}

p {
  font-size: 16px;
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  gap: 10px;
}

.find-out-more,
.join-the-list {
  margin-top: 20px;
  padding: 10px;
  border-radius: 50px;
  border: 0;
  color: white;
  width: 150px;
}
.find-out-more {
  background-color: orange;
}
.join-the-list {
  background-color: #86c351;
}
