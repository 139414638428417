.pet-add-form {
  .form-group {
    .react-select-custom {
      margin-top: 5px;
    }
  }
}

.pet-added-form {
  .main-form {
    img {
      max-width: 200px;
    }
  }
  .btn-checkout {
    background: var(--theme-light);
  }
}
