.icon-container-password {
    display: flex;
    flex-direction: column;
    align-items: center;

    .password-icon {
        background: var(--yellow-2, #FFF2D6);
        border-radius: 50%;
        width: 170px;
        height: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}