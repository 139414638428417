.meal-planner-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 80px;
  background-color: #d2eff4;
}
h1 {
  color: #59a4b4;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 50px;
}
.pera {
  font-weight: 400;
  line-height: 30px;
  font-size: x-large;
  margin-bottom: 50px;
}
.img1 {
  margin-right: 40px;
  width: 200px;
}
.img1:hover {
  scale: 1.1;
  transition: 0.5s;
}

.img2 {
  width: 200px;
}
.img2:hover {
  scale: 1.1;
  transition: 0.5s;
}
