.modal {
  display: block;
  background: rgba(37, 22, 22, 0.079);
  z-index: 99999;
  .modal-dialog {
    .modal-content {
    }
    button.close {
      position: absolute;
      top: 20px;
      right: 20px;
      background: none;
      padding: 0px;
      outline: none;
      box-shadow: none;
      border: 0px;
      cursor: pointer;
      z-index: 99999;
      svg {
        max-width: 20px;
      }
    }
    .modal-header {
      text-align: center;
      align-items: center;
      justify-content: center;
      border-bottom: 0px;
      .modal-title {
        text-align: center;
        h5 {
          margin-bottom: 0px;
        }
      }
    }
  }
}
