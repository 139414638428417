.single-pet-wrapper {
  &.deceased {
    opacity: 0.8;
    background: #ebebeb;
    filter: grayscale(1);
  }
}
.more-options-dropdown {
  position: relative;
  .trigger {
    position: relative;
    top: -20px;
    button {
      padding: 0px;
      margin: 0px;
      svg {
        max-height: 36px;
      }
    }
  }
  .dropdown-wrapper {
    // display: none;
    position: absolute;
    right: 40px;
    top: -20px;
    background: #fff0cc;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);
    ul {
      padding: 0px;
      margin: 0px;
    }
    ul li {
      button {
        min-width: 200px;
        width: fit-content;
        padding: 6px 12px;
        text-align: left;
        font-size: 16px;
        font-weight: 500;
        &:hover {
          background: #fff7e4;
        }
      }
    }
  }
}
