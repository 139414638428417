.add-new-address {
  .add-new-actions {
    button {
    }
    .add-button,
    .cancel-button {
      padding: 14px;
      border-radius: 5px;
      font-size: 18px;
      font-weight: 500;
      width: 320px;
      display: inline-block;
      color: #222;
      text-decoration: none;
      &:hover {
        opacity: 0.75;
      }
    }
    .add-button {
      background: var(--theme);
    }
    .cancel-button {
      background: var(--theme-light);
    }
  }
}
.custom-map-wrapper {
  height: 650px;
  cursor: pointer;
}
.location-search-input {
  width: "30rem";
}
