body {
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-thumb {
  background-color: #e8b55e;
  border-radius: 6px;
}

body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* For Firefox */
// body {
//   scrollbar-width: thin;
//   scrollbar-color: #4caf50 #f1f1f1;
// }

.auth-form {
  h4 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  .btn-text {
    color: var(--link) !important;
  }

  button.main-btn {
    width: 100%;
    display: flex;
    padding: 14px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    background: var(--theme);
    border: none;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;

    &:hover {
      background: var(--theme) !important;
      opacity: 0.8;
    }

    &:disabled {
      background: var(--theme) !important;
      opacity: 0.5;
    }
  }
}

.page-content {
  min-height: 70vh;
}

.create-form {
  .input-form-wrapper .auth-form-field {
    border-bottom: 0px;

    input.form-control {
      border: 1px solid #f9f9f9;
      background: #f9f9f9;
      height: 46px;

      &:disabled {
        background: #eee;
      }
    }
  }

  .select-wrap {
    margin-top: 5px;
  }

  .react-select-custom__control {
    border: 1px solid #f9f9f9;
    background: #f9f9f9;
    height: 46px;
  }
}
.element {
  padding-top: 20px;
  padding-left: 8px; /* px-2 */
  padding-right: 8px;
}

@media (min-width: 768px) {
  /* md:px-5 */
  .element {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 1024px) {
  /* lg:px-7 */
  .element {
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 28px;
  }
}
.btn-theme {
  padding: 10px 20px;
  background: var(--theme);
  // width: 100%;
  font-size: 18px;
  font-weight: 600;

  &:hover {
    background: var(--theme);
    opacity: 0.8;
  }
}

.btn-theme-light {
  padding: 10px 20px;
  background: var(--yellow-2, #fff2d6);
  font-size: 18px;
  font-weight: 600;

  &:hover {
    background: var(--yellow-2, #fff2d6);
    opacity: 0.8;
  }
}

.btn-login {
  padding: 10px 25px;
  border-radius: 133px;
  background: var(--orange, #e68f00);
  color: #fff;
  font-size: 18px;
  font-weight: 600;

  &:hover {
    background: var(--orange, #e68f00);
    color: #fff;
  }

  &.btn-signup {
    background: var(--blue, #448ebe);
    margin-left: 20px;
  }
}

.text-green {
  color: #8dc63f !important;
}

@media screen and (max-width: 576px) {
  .header {
    .logo-wrapper {
      max-width: 150px;

      svg {
        max-width: 150px;
      }
    }

    .main-actions-nav {
      padding-left: 0px;

      li {
        button {
          padding: 0px 12px;
          font-size: 14px;
        }
      }
    }
  }

  .home-section {
    .left-dog-wrapper,
    .right-dog-wrapper {
      // display: none;
    }

    .home-banner {
      padding: 150px 30px;
      padding-bottom: 200px;
      display: block;
      height: auto;

      .search-container {
        padding: 5px 10px;

        .search-icon {
          padding: 0px;

          svg {
            max-width: 32px;
          }
        }

        .search-input {
          input {
            padding: 5px 0px;
            min-width: 120px;
          }
        }

        .search-btn {
          button {
            width: 36px;
            height: 36px;
          }
        }
      }
    }

    .main-banner-content {
      max-width: 100%;

      h1 {
        font-size: 1.4rem;
        line-height: 130%;
      }
    }
  }

  .app-cta-banner {
    .row {
      flex-direction: column-reverse;
    }

    .side-image {
      bottom: 0px !important;
    }

    .image-section {
      .ios-image {
        width: 150px !important;
      }
    }
  }

  .modal {
    .modal-dialog {
      min-width: auto !important;
    }
  }
  .nutrition-plan .premium-plan-section .image-premium-plan .availability {
    position: initial !important;
    h4 {
      font-size: 28px !important;
    }
    p {
    }
  }
  .nutrition-plan .premium-plan-section .image-premium-plan {
    img {
      width: 100%;
    }
  }
}
@media (max-width: 1286px) {
  .hidden-below-1286 {
    display: none;
  }
}

@media (min-width: 1287px) {
  .hidden-above-1286 {
    display: none;
  }
}
.padding-sm {
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 768px) {
  .padding-md {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (min-width: 1024px) {
  .padding-lg {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.padding-small {
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 768px) {
  .padding-med {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 1124px) {
  .padding-large {
    padding-left: 80px;
    padding-right: 80px;
  }
}
.responsive-div {
  display: none;
}

@media (min-width: 832px) {
  .responsive-div {
    display: block;
  }
}
.responsive-width-div {
  width: 100%; /* Full width by default */
}

@media (min-width: 832px) {
  .responsive-width-div {
    width: 24rem; /* Fixed width of 24rem for larger screens */
  }
}
