.app-cta-banner {
    // padding: 80px;
    .download-app-now-section {
        color: var(--yellow, #E68F00);
    }
    .lead {       
        font-weight: 400;
    }

    .image-section {
        margin-top: 30px;
        .ios-image {
            width: 200px;
        }
    }

    .side-image {
        position: relative;
        right: 0px;
        bottom: 114px;
        width: 100%;
    }
}