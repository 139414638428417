.home-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 700px;
  background: #ffe0ba;
  position: relative;
  .main-banner-content {
    max-width: 750px;
    text-align: center;
    position: relative;
  }
  .head {
    color: white;
    background-color: rgb(234, 153, 3);
    width: 500px;
    align-items: center;
    text-align: center;
    margin: auto;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
  }
  .left-dog-wrapper {
    position: absolute;
    left: 0px;
    bottom: 0px;
    img {
      width: 25vw;
    }
  }
  .right-dog-wrapper {
    position: absolute;
    right: 0px;
    bottom: 0px;
    img {
      width: 25vw;
    }
  }

  h6 {
    // font-family: 'Nimbus Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  h1 {
    color: var(--text, #2d2d2d);
    text-align: center;
    font-family: "Rubik";
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .search-container {
    display: inline-flex;
    align-items: center;
    border: 1px solid #222;
    background: #fff;
    gap: 10px;
    width: 100%;
    padding: 10px;
    border-radius: 48px;
    border: 2px solid rgba(230, 143, 0, 0.25);
    background: var(--White, #fff);
    height: 80px;
    margin-top: 30px;
    .search-input {
      flex-grow: 1;
      input {
        min-width: 400px;
        flex-grow: 1;
        width: 100%;
        padding: 15px 0px;
        border: none;
        outline: none;
        font-size: 24px;
      }
    }
    .search-icon {
      padding: 10px;
    }
    .search-btn {
      button {
        padding: 10px;
        border-radius: 50%;
        width: 54px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--theme);
        border: none;
        outline: none;
        svg {
        }
      }
    }
  }
}
