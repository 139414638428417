.input-form-wrapper{
  margin-bottom: 20px;
  .auth-form-field{
    border: none;
    padding: 5px 0px;
    border-bottom: 1px solid #D8D8D8;
    margin-bottom: 4px;
    &.has-error {
      border-bottom: 1px solid red;
    }
    .input-group-text{
      background: none;
      border-right: 0px;
      border: none;
      padding: 0px 5px;
      svg {
        max-width: 20px;
      }
    }
    input.form-control {
      border-left: none;
      border: none;
      outline: none;
      box-shadow: none;
      &::placeholder {
        color: rgba(0, 0, 0, 0.50);
      }
    }
  }
  .form-error{
    font-size: 12px;
    color: red;
    margin-top: 5px;
  }
}
.form-group{
  label {
    margin-bottom: 6px;
    color: var(--text, #2D2D2D);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }
  input {
    box-shadow: none;
    outline: none;
    background: #F9F9F9;
    padding: 10px 16px;
    height: auto;
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
}