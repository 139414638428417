.home-section-alt {
  background: #fdf4e9;
  padding: 30px;

  .home-banner-alt {
    .home-banner-content {
      padding: 50px;
      margin-top: 60px;

      h1 {
        color: var(--orange, #e68f00);
        font-weight: 700;
      }
    }
  }
}
