.myplan {
  .plan-info-wrapper {
    max-width: 800px;
    text-align: center;
    margin: 0px auto;

    h1 {
    }
  }

  .avail-features {
    text-align: center;
    margin: 30px 0px;
    button {
      border: 2.5px solid #e68f00;
      color: #e68f00;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      max-width: 350px;
      background: none;
      padding: 15px;
      margin: 0px auto;
      border-radius: 10px;
    }
  }

  .plans {
    max-width: 500px;

    margin: 0px auto;
    margin-top: 30px;
    .plan-group {
      max-width: 500px;
      border: 1.695px solid #fdb913;
      background: #fdf6e4;
      padding: 20px 25px;
      border-radius: 10px;
      margin-bottom: 30px;
      .single-plan {
        display: flex;
        align-items: center;
        gap: 15px;
        margin: 10px 20px;
        padding-right: 100px;
        cursor: pointer;
        span.radio {
        }
        .text {
          font-size: 22px;
          font-weight: 600;

          .price {
            font-weight: 500;
            font-size: 21px;
          }
        }
      }
      .plan-group-title {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        .text {
          font-size: 24px;
          font-weight: 600;
        }
      }
    }
    .plan-action {
      text-align: center;
      margin: 30px 0px;
      button {
        padding: 12px 30px;
        background: #e68f00;
        color: var(--black, #000);
        border: 2px solid #e68f00;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 5px;
      }
    }
  }

  .plan-radio {
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
    border: 2px solid #794e00;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &.checked {
      &:after {
        content: "";
        width: 10px;
        height: 10px;
        display: block;
        background: #222;
        border-radius: 50%;
      }
    }
  }

  .plan-actions {
  }
}
