.faq-section {
  .card-border {
    border: 2px solid #e68f00;
    border-radius: 25px;

    h1 {
      color: #e68f00;
    }
  }
  .bold-pera {
    font-weight: 800;
    font-size: 20px;
  }
}
