.pet-details-page {
    .pet-details {
        .icon-wrapper {
            color: black !important;
        }

        button {
            width: 500px;
            text-align: center;
            justify-content: center;
        }
    }

    .border-wrapper {
        border-radius: 16.112px;
        border: 2.014px solid #FFF0CC;
    }

    div.side-wrapper {
        position: relative;
        width: 400px;
    }

    div.dot-section {
        position: absolute;
        right: 0px;
        bottom: 50px;
    }

}