.single-address {
  margin-bottom: 30px;
  border-radius: 20px;
  overflow: hidden;
  // background-color: #aeaeae;
  .round {
    position: relative;
    margin-right: 15px;
  }

  .round label {
    background-color: #fff;
    border: 3px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
  }

  .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
  }

  .round input[type="checkbox"] {
    visibility: hidden;
  }

  .round input[type="checkbox"]:checked + label {
    background-color: #66bb6a;
    border-color: #66bb6a;
  }

  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }

  .icon-container {
    background-color: #e63826;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    &.my-address-icon {
      background-color: #3aa9fa;
      border-radius: 50%;
    }
  }
}
