.header {
  height: 84px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 999;
  border-bottom: 1px solid #eee;
  // background: #f1f1f1;

  ul {
    list-style: none;
  }

  .logo-wrapper {
    svg {
      max-height: 48px;
    }
  }

  .main-actions-nav {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    margin: 0px;

    button {
      display: inline-flex;
      padding: 8px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border: none;
      background: none;
      outline: none;
      border-radius: 40px;
      color: #000;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
      cursor: pointer;

      &.signup {
        background: var(--theme);
      }
    }
  }
}

.header-dropdown {
  position: relative;
  .trigger {
    cursor: pointer;
    user-select: none;
  }

  .down-icon {
    display: flex;
    max-height: 20px;
    // margin-left: 700px;
    margin-top: 10px;
    margin-left: 5px;
    transition: all 0.1s;
    opacity: 0.6;
    &.open {
      transform: rotate(180deg);
    }
  }
  @media (max-width: 767px) {
    margin-left: 0;
  }
  .dropdown {
    position: absolute;
    right: 0px;
    top: 64px;
    background: #fff;
    box-shadow: 0px 0px 5px rgba($color: #000000, $alpha: 0.1);
    ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
    }
    li a,
    li button {
      // height: 60px;
      padding: 10px 16px;
      text-align: left;
      min-width: 200px;
      display: block;
      border: none;
      border-bottom: 1px solid #eee;
      text-decoration: none;
      color: var(--dark);
      user-select: none;
      outline: none !important;
      background: #fff;
      &:focus,
      &:active {
        outline: none !important;
        border: none;
        border-bottom: 1px solid #eee;
      }
      &:hover {
        background-color: #f8f8f8;
      }
    }
  }
  @media (max-width: 1290px) {
    margin-right: 200px;
  }
}
