.footer-wrap {
    background-color: #101010;
    color: #fff;
    font-size: 20px;

    .list-unstyled {
        .list-content {
            opacity: 0.5;
            a {
                text-decoration: none;
                color: #fff;
            }
            .email-icon {
                background-color: #fff;
            }
        }

    }
    .by-oyelabs {
        font-size: 14px;  
        margin-top: 25px;
        .oyelabs-content {
            opacity: 0.5;

        }
    }
}