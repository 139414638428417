.footer-alt-wrap {
  background: #ffffff;

  h4 {
    color: var(--orange, #e68f00);
  }

  .address-section {
    a {
      color: #101010;
      text-decoration: none;
    }
  }

  .image-wrapper {
    img {
      width: 150px;
    }
  }
  .logo-image-wrapper {
    margin-top: 50px;
    img {
      width: 250px;
    }
  }
}
.phone {
  width: 200px;
  margin-left: 60px;
  margin-bottom: 20px;
}
.logo-socials {
  font-size: 40px;
  margin-right: 20px;
  background-color: rgb(44, 171, 199);
  border-radius: 50%;
  padding: 10px;
  color: #fdf4e9;
  cursor: pointer;
  transform: translate();
}
.logo-socials:hover {
  scale: 1.1;
  transition: 0.5s;
}
.footer-sub-section {
  padding: 1px;
  background: var(--orange, #e68f00);
  align-items: center;
  color: #000000;
  font-size: medium;
}
