.header-alt {
  align-items: center;
  padding: 20px;
  position: relative;
  z-index: 9999;
  border: 1px solid #e8e5e3;

  .logo-wrapper {
    svg {
      max-height: 48px;
    }
  }
}
