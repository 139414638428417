.pet-list-page {
  text-align: center;
  .dog-img-wrapper {
    max-width: 300px;
    margin: 40px auto;
  }
  .pet-actions {
    text-align: center;
    button {
      background: var(--theme);
      padding: 8px 16px;
      border-radius: 4px;
      display: flex;
      gap: 10px;
      border: none;
      align-items: center;
      margin: 0px auto;
      span.label {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}
