.password-reset-sent{
  .img-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    img {
      width: 100%;
      max-width: 200px;
    }
  }
  
}